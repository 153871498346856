import { useState } from 'react';
import styled from '@emotion/styled';

import config from 'config';
import { useLayoutEffectWithoutSsrWarning } from 'services/hooks';
import { useTranslation } from 'react-i18next';
import { mapgl } from '../mapUtils';
import ZoomButtons from './ZoomButtons';

export const BaseContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: #f8f4f0; /* Background color of jawg-sunny. */
`;

function setupMap(containerRef, {
  initialCenter, displayScale, initialZoom, initialBounds,
}) {
  const centering = initialBounds ? {
    bounds: initialBounds,
  } : {
    center: initialCenter,
    zoom: initialZoom,
  };

  const map = new mapgl.Map({
    style: config.openmaptiles.url,
    container: containerRef,
    ...centering,
  });

  map.addControl(new mapgl.FullscreenControl());
  map.addControl(new ZoomButtons());
  map.boxZoom.disable();

  map.addControl(new mapgl.GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true,
    },
    trackUserLocation: true,
    fitBoundsOptions: { maxZoom: 11 },
  }), 'top-right');

  if (displayScale) {
    map.addControl(new mapgl.ScaleControl());
  }
  return map;
}

export default function useMapBase(props) {
  const [containerRef, setContainerRef] = useState(null);
  const [map, setMap] = useState(null);
  const { i18n: { language } } = useTranslation();

  useLayoutEffectWithoutSsrWarning(() => {
    if (map || !containerRef) {
      return undefined; // No Cleanup callback.
    }

    const newMap = setupMap(containerRef, props);
    newMap.once('styledata', () => { // Wait for the download of styles before making the map available.
      newMap.setLanguage(language);
      setMap(newMap);
    });

    return () => {
      setMap(null);
      setContainerRef(null);
      setTimeout(newMap.remove.bind(newMap), 0);
      // FIXME: we should also cleanup on container unmount (setContainerRef null).
    };
  }, [containerRef]);

  return [
    setContainerRef,
    map,
  ];
}
