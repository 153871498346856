import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import config from 'config';
import ogImageDefault from './og-image-default.jpg';

export default function SeoMeta({
  title,
  description,
  languageCode,
  canonicalUrl,
  ogImage = ogImageDefault,
  hrefLangs,
  children,
}) {
  const { t, i18n: { language } } = useTranslation();
  const image = (ogImage.startsWith('https://') || ogImage.startsWith('http://'))
    ? ogImage : `${config.rootUrl}${ogImage}`;

  return (
    <Helmet>
      <html lang={languageCode || language} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} />
      <meta property="og:locale" content={language} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:site_name" content={t('ride_sharing_site_title')} />

      <meta property="og:image" content={image} />

      {/* Don't set twitter:card content=app because it displays smaller images. */}
      <meta name="twitter:description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta name="twitter:site" content="@_LibertyRider_" />
      <meta name="twitter:site:id" content="3700636996" />
      <meta name="twitter:app:name:iphone" content={t('app_name_iphone')} />
      <meta name="twitter:app:id:iphone" content="1139116439" />
      <meta name="twitter:app:name:googleplay" content={t('app_name_android')} />
      <meta name="twitter:app:id:googleplay" content="com.libertyrider.angel" />
      {hrefLangs.map(([lng, href]) => <link key={lng} rel="alternate" href={href} hrefLang={lng} />)}
      {children}
    </Helmet>
  );
}

SeoMeta.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  languageCode: PropTypes.string,
  canonicalUrl: PropTypes.string.isRequired,
  ogImage: PropTypes.string,
  hrefLangs: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  children: PropTypes.node,
};

SeoMeta.defaultProps = {
  languageCode: undefined,
  ogImage: undefined,
  hrefLangs: [],
  children: undefined,
};
