import { useEffect, useState } from 'react';
import polylineLib from '@mapbox/polyline';
import 'maplibre-gl/dist/maplibre-gl.css';
import bindSetLanguage from './mapLanguage';

let mapglDynamic = null;
if (process.env.BUILD_TARGET !== 'server') {
  // The library does not work server-side (needs the DOM).
  mapglDynamic = require('maplibre-gl'); // eslint-disable-line global-require
  bindSetLanguage(mapglDynamic);
}
export const mapgl = mapglDynamic;

export function setMapLayerData({
  map,
  layerId,
  sourceId,
  style = {},
  geojson,
  beforeId = null,
}) {
  const source = map.getSource(sourceId);
  if (source) {
    source.setData(geojson);
  } else {
    map.addSource(sourceId, {
      type: 'geojson',
      data: geojson,
    });
  }

  const layer = map.getLayer(layerId);
  if (!layer) {
    map.addLayer({
      id: layerId,
      source: sourceId,
      ...style,
    }, beforeId);
  }
}

export function ReplayPolyline(props) {
  const [partialLength, setPartialLength] = useState(0);

  const { polyline, interval: intervalDuration, children } = props;
  const points = polylineLib.decode(polyline);

  useEffect(() => {
    if (partialLength < points.length) {
      const interval = setInterval(() => {
        setPartialLength(partialLength + 1);
      }, intervalDuration);
      return () => clearInterval(interval);
    }
    return undefined; // No Cleanup callback.
  }, [partialLength, setPartialLength]);

  const partialPolyline = polylineLib.encode(points.slice(0, partialLength));
  return children(partialPolyline);
}

function addPadding(bounds) {
  // The map.fitBounds() method has a 'padding' arg but it used to cause tiles to be loaded twice.
  const corners = bounds.toArray();
  const percent = 0.15;
  const horizontalPadding = (corners[1][0] - corners[0][0]) * percent;
  const verticalPadding = (corners[1][1] - corners[0][1]) * percent;

  return mapgl.LngLatBounds.convert([
    [corners[0][0] - horizontalPadding, corners[0][1] - verticalPadding],
    [corners[1][0] + horizontalPadding, corners[1][1] + verticalPadding],
  ]);
}

export function getBounds(lngLats) {
  const bounds = new mapgl.LngLatBounds();
  lngLats.map((lngLat) => bounds.extend(lngLat));

  return addPadding(bounds);
}

export const emptyGeojson = {
  type: 'FeatureCollection',
  features: [],
};
