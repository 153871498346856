import React from 'react';
import ReactDOMClient from 'react-dom/client';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Container = styled.div`
  position: fixed;
  display: flex;
  top: 5px;
  right: 45px;
  @media (max-width: 800px) {
    display: none;
  }
`;
const Button = styled.button`
  width: 29px;
  height: 29px;
  display: block;
  margin: 5px;
  padding: 0;
  border: 0;
  outline: none;
  box-sizing: border-box;
  pointer-events: auto;
  cursor: pointer;
  border-radius: 5px;
  background: #FFFFFF;
  box-shadow: 0 0 0 2px rgba(0,0,0,.1);
  font-style: normal;
  font-size: 20px;
  font-weight: 600;
  user-select: none;
  &:hover {
    background-color: #F0F0F5;
  }
`;

function ZoomButton({ title, onClick, children }) {
  return (
    <Button type="button" title={title} onClick={onClick}>
      {children}
    </Button>
  );
}
ZoomButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

// FIXME: remove this custom component and use mapgl.NavigationControl like roadbook-react?
export default class ZoomButtons {
  constructor() {
    this.container = document.createElement('div');

    ReactDOMClient.createRoot(this.container).render(
      <Container onContextMenu={(e) => e.preventDefault()}>
        <ZoomButton title="Zoom −" onClick={() => this.map.zoomOut()}>
          −
        </ZoomButton>
        <ZoomButton title="Zoom +" onClick={() => this.map.zoomIn()}>
          +
        </ZoomButton>
      </Container>,
    );
  }

  onAdd(map) {
    this.map = map;
    return this.container;
  }

  onRemove() {
    if (this.container.parentNode) {
      this.container.parentNode.removeChild(this.container);
    }
    delete this.map;
  }
}
