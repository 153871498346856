import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';

import LogoAndGradient from 'components/RideViewLayout/LogoAndGradient';
import PageLoading from 'components/PageLoading';
import HttpStatus from 'components/HttpStatus';
import RideError from 'components/RideError';
import RideSharing from 'components/RideSharing';

const GET_RIDE_BY_RIDE_SHARING_TOKEN = gql`
  query rideSharingMap($token: String!) {
    ride: rideBySharingToken(sharingToken: $token) {
      id
      state
      ...RideSharingFragment
    }
  }
  ${RideSharing.fragments.rideSharing}
`;

const GET_RIDE_BY_USER_SHARING_TOKEN = gql`
  query rideSharingMapByUserCurrentRideSharingToken($token: String!) {
    ride: rideByUserCurrentRideSharingToken(token: $token) {
      id
      state
      ...RideSharingFragment
    }
  }
  ${RideSharing.fragments.rideSharing}
`;

const POLL_INTERVAL_AT_START = 5000;
const POLL_INTERVAL_AFTER_1_MINUTE = 10000;
const POLL_INTERVAL_AFTER_2_MINUTES = 30000;
const MS_ONE_MINUTE = 60 * 1000;

export default function RideSharingMap({ sharingToken, userCurrentRideSharingToken }) {
  const [stopped, setStopped] = useState(false);
  const { t } = useTranslation();
  const [pollInterval, setPollInterval] = useState(POLL_INTERVAL_AT_START);
  useEffect(() => {
    const timer = setTimeout(
      () => setPollInterval(POLL_INTERVAL_AFTER_1_MINUTE),
      MS_ONE_MINUTE,
    );
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    const timer = setTimeout(
      () => setPollInterval(POLL_INTERVAL_AFTER_2_MINUTES),
      MS_ONE_MINUTE * 2,
    );
    return () => clearTimeout(timer);
  }, []);

  const {
    loading, error, data, stopPolling,
  } = useQuery(sharingToken ? GET_RIDE_BY_RIDE_SHARING_TOKEN : GET_RIDE_BY_USER_SHARING_TOKEN, {
    variables: { token: sharingToken || userCurrentRideSharingToken },
    pollInterval: stopped ? null : pollInterval,
  });

  if (loading && !data) {
    return <PageLoading />;
  }
  if (error) {
    if (!data) {
      return <p>{t('loading_error')}</p>;
    }
    console.error('Ignoring refresh error', error); // eslint-disable-line no-console
  }

  const { ride } = data;
  if (!ride) {
    return (
      <HttpStatus code={404}>
        <>
          <LogoAndGradient />
          <RideError />
        </>
      </HttpStatus>
    );
  }

  if (ride.state === 'RIDE_STOPPED' && !stopped) {
    stopPolling();
    setStopped(true);
  }

  return <RideSharing ride={ride} />;
}

RideSharingMap.propTypes = {
  sharingToken: PropTypes.string,
  userCurrentRideSharingToken: PropTypes.string,
};

RideSharingMap.defaultProps = {
  sharingToken: null,
  userCurrentRideSharingToken: null,
};
