import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';

import config from 'config';
import SeoMeta from 'components/SeoMeta';
import { formatDistance, formatSeconds, formatDate } from 'services/utils';

export default function RideMeta({ ride }) {
  const { t, i18n: { language } } = useTranslation();

  const {
    state,
    previewPictureUrl,
    startTime,
    duration,
    distance,
    user,
    sharingToken,
  } = ride;

  const formattedDate = formatDate(new Date(startTime), language);
  const formattedDuration = formatSeconds(duration);
  const formattedDistance = formatDistance(distance);

  const userFirstname = user && user.firstName;
  const first = userFirstname
    ? t('ride_sharing_seo_title_withname', { first_name: userFirstname })
    : t('ride_sharing_seo_title_withoutname');
  const second = state !== 'RIDE_STOPPED'
    ? ''
    : ` - ${t('ride_sharing_seo_subtitle', { distance: formattedDistance, duration: formattedDuration })}`;
  const title = `${first} ${formattedDate}${second}`;

  return (
    <SeoMeta
      title={title}
      description={t('ride_sharing_seo_description')}
      ogImage={previewPictureUrl || undefined}
      canonicalUrl={`${config.sharingUrl}/${language}/p/${sharingToken}`}
      hrefLangs={config.mainLanguages.map((lng) => [lng, `${config.sharingUrl}/${lng}/p/${sharingToken}`])}
    />
  );
}

RideMeta.propTypes = {
  ride: PropTypes.shape({
    state: PropTypes.string,
    previewPictureUrl: PropTypes.string,
    startTime: PropTypes.string,
    duration: PropTypes.number,
    distance: PropTypes.number,
    sharingToken: PropTypes.string,
    user: PropTypes.shape({
      firstName: PropTypes.string,
    }),
  }).isRequired,
};

RideMeta.defaultProps = {
};

RideMeta.fragments = {
  rideMeta: gql`
    fragment RideMetaFragment on Ride {
      sharingToken
      state
      duration
      distance
      previewPictureUrl(width: 600, height: 315, scale: 2, languageCode: $languageCode)
      user {
        id
        firstName
      }
      startTime
    }
  `,
};
