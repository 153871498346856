import React from 'react';
import styled from '@emotion/styled';

import endMarker from './end.svg';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #FFFFFF;
`;
const Img = styled.img`
  width: 30px;
  height: 30px;
`;

function ArrivalIcon() {
  return (
    <Container aria-hidden>
      <Img src={endMarker} />
    </Container>
  );
}

ArrivalIcon.propTypes = {};

ArrivalIcon.defaultProps = {};

export default ArrivalIcon;
