import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const PinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${(props) => props.color || '#FF6158'};
`;
const Svg = styled.svg`
  width: 16px;
  height: 16px;
`;

function LocationIcon({ color }) {
  return (
    <PinContainer aria-hidden color={color}>
      <Svg viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.50007 13.6591C6.50007 13.6591 12.0682 9.97106 12.0682 5.66838C12.0682 2.61312 9.57529 0.136353 6.50007 0.136353C3.42484 0.136353 0.931885 2.61312 0.931885 5.66838C0.931885 9.97106 6.50007 13.6591 6.50007 13.6591ZM6.50007 2.68201C4.85074 2.68201 3.5137 4.01905 3.5137 5.66838C3.5137 7.3177 4.85074 8.65474 6.50007 8.65474C8.14939 8.65474 9.48643 7.3177 9.48643 5.66838C9.48643 4.01905 8.14939 2.68201 6.50007 2.68201ZM4.7137 5.66838C4.7137 4.6818 5.51349 3.88201 6.50007 3.88201C7.48665 3.88201 8.28643 4.6818 8.28643 5.66838C8.28643 6.65496 7.48665 7.45474 6.50007 7.45474C5.51349 7.45474 4.7137 6.65496 4.7137 5.66838Z" fill="white" />
      </Svg>
    </PinContainer>
  );
}

LocationIcon.propTypes = {
  color: PropTypes.string,
};

LocationIcon.defaultProps = {
  color: null,
};

export default LocationIcon;
