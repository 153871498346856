import React, { useState } from 'react';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';

import RideDetails from 'components/RideDetails';
import RideViewLayout from 'components/RideViewLayout';
import WaitingForSignal from 'components/WaitingForSignal';
import { RideMapContainer, useRideMap } from 'components/RideMap';
import RiderCenterButton from './RiderCenterButton';

export default function RideSharing({ ride }) {
  const [followRider, setFollowRider] = useState(true);
  const [mapRef] = useRideMap({
    detailedPolyline: ride && ride.detailedPolyline,
    state: ride && ride.state,
    pauses: ride && ride.pauses,
    followRider,
    initialZoom: 14,
    userMoveInitiated: () => setFollowRider(false),
  });

  return (
    <RideViewLayout
      map={(ride && ride.detailedPolyline)
        ? <RideMapContainer ref={mapRef} />
        : <WaitingForSignal />}
      actionButtons={!followRider && (
        <RiderCenterButton
          ride={ride}
          onClick={() => setFollowRider(true)}
        />
      )}
      details={<RideDetails ride={ride} />}
    />
  );
}

RideSharing.propTypes = {
  ride: PropTypes.shape({
    detailedPolyline: PropTypes.string,
    state: PropTypes.string,
    pauses: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

RideSharing.defaultProps = {};

RideSharing.fragments = {
  rideSharing: gql`
    fragment RideSharingFragment on Ride {
        detailedPolyline
        state
        pauses {
          lastLocation {
            latitude
            longitude
          }
        }
      ...RideDetailsFragment
    }
    ${RideDetails.fragments.rideDetails}
  `,
};
