import React from 'react';
import { useAsync } from 'react-async-hook';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import polylineLib from '@mapbox/polyline';

import config from 'config';
import { getAuthTokenForHttp } from 'services/global-auth-state';
import PageLoading from 'components/PageLoading';
import RideError from 'components/RideError';
import HttpStatus from 'components/HttpStatus';
import RideIdForm from 'components/MapAdmin/RideIdForm';
import { useIsClient } from 'services/hooks';
import AdminRequired from 'components/AdminRequired';
import MapAdmin from 'components/MapAdmin';

const ownFragments = gql`
  fragment EventFragment on RideEvent {
    id # Displayed in the UI.
    type
    location: lastLocation {
      latitude
      longitude
      accuracy
    }
    estimatedTime
    details
  }
  fragment RideEventFragment on Ride {
    events { # For legacy emergency debug, and ACCIDENT_SURVEY for SDK apps.
      ...EventFragment
    }
    pauses {
      ...EventFragment
      automatic
    }
    resumes {
      ...EventFragment
      automatic
    }
  }
`;
const GET_RIDE_BY_TOKEN = gql`
  query rideBySharingTokenForAdminReactFrontend($token: String!) {
    ride: rideBySharingToken(sharingToken: $token) {
      id
      ...RideEventFragment
      ...MapAdminFragment
    }
  }
  ${ownFragments}
  ${MapAdmin.fragments.mapAdmin}
`;
const GET_RIDE_BY_ID = gql`
  query rideByIdForAdminReactFrontend($id: ID!) {
    ride(id: $id) {
      id
      ...RideEventFragment
      ...MapAdminFragment
    }
  }
  ${ownFragments}
  ${MapAdmin.fragments.mapAdmin}
`;

async function fetchTrackDebug(rideId) {
  const jwtoken = getAuthTokenForHttp();
  const request = await fetch(
    `${config.trackApiUrl}/rides/${rideId}/debug`,
    { headers: { authorization: `Bearer ${jwtoken}` } },
  );
  const json = await request.json();

  if (json.error) {
    throw new Error(`Cannot get track debug: ${json.error}`);
  }
  return json;
}

async function fetchEmergencyDebug({ sessionIds, rideId }) {
  const jwtoken = getAuthTokenForHttp();
  const request = await fetch(
    `${config.emergencyApiUrl}/v1/sessions/debug?sessionIds=${sessionIds.join(',')}&rideId=${rideId}`,
    { headers: { authorization: `Bearer ${jwtoken}` } },
  );
  const json = await request.json();

  if (json.error) {
    throw new Error(`Cannot get emergency debug: ${json.error}`);
  }
  return json;
}

function AuthenticatedAdminPage({
  rideId, authStatus, sharingToken, sessionIds,
}) {
  const query = rideId ? GET_RIDE_BY_ID : GET_RIDE_BY_TOKEN;

  const { loading: loading1, error: error1, data: graphqlData } = useQuery(query, {
    variables: {
      id: rideId || null,
      token: sharingToken || null,
    },
    skip: !rideId && !sharingToken,
  });
  const actualRideId = rideId || (graphqlData && graphqlData.ride && graphqlData.ride.id);
  const { loading: loading2, error: error2, result: trackDebug } = useAsync(
    () => (actualRideId && fetchTrackDebug(actualRideId)),
    [actualRideId],
  );
  const { loading: loading3, error: error3, result: emergencyDebug } = useAsync(
    () => fetchEmergencyDebug({ sessionIds, rideId: actualRideId }),
    [sessionIds, actualRideId],
  );

  if (loading1 || loading2 || loading3) {
    return <PageLoading />;
  }
  if (error1 || error2 || error3) {
    return (
      <>
        <RideIdForm rideId={rideId} sharingToken={sharingToken} />
        {authStatus}
        <p>
          {(error1 || '').toString()}
          {(error2 || '').toString()}
          {(error3 || '').toString()}
        </p>
      </>
    );
  }

  let { ride } = graphqlData || {};
  const { debugTrack = {}, debugLocations = [] } = trackDebug || {};
  const { sessions = [], events: newEvents = [], emergencies = [] } = emergencyDebug || {};

  if (!ride && !emergencyDebug) {
    return (
      <HttpStatus code={404}>
        <>
          <RideIdForm rideId={rideId} sharingToken={sharingToken} />
          {authStatus}
          <RideError />
        </>
      </HttpStatus>
    );
  }
  if (!ride) {
    const centerLocation = emergencies?.[0]?.emergencyLocation || newEvents?.[0]?.location || {};
    const polyline = emergencies?.[0]?.polyline
      || polylineLib.encode([[centerLocation.latitude, centerLocation.longitude]]);
    ride = {
      events: [],
      pauses: [],
      resumes: [],
      detailedPolyline: polyline,
    };
  }

  const legacyEvents = ride.events.filter((event) => (event.type !== 'PAUSE' && event.type !== 'RESUME' && event.type !== 'EMERGENCY_REQUEST'));
  const legacyEmergencies = ride.events.filter((event) => event.type === 'EMERGENCY_REQUEST');

  const emergencyLocations = [
    ...emergencies.map((emergency) => emergency.emergencyLocation),
    ...legacyEmergencies.map((emergency) => emergency.location),
  ];

  const events = [
    ...newEvents,
    ...legacyEvents.map(({ details, __typename, ...event }) => ({
      ...event, features: JSON.parse(details),
    })),
    ...ride.pauses.map(({ automatic, ...event }) => ({
      ...event, features: { automatic },
    })),
    ...ride.resumes.map(({ automatic, ...event }) => ({
      ...event, features: { automatic },
    })),
    ...emergencies.map(({
      emergencyId, emergencyLocation, estimatedTime, firstName, lastLocationTime, lastName,
      polyline, sessionId, cancellationTime,
    }) => ({
      id: emergencyId,
      type: 'EMERGENCY',
      location: emergencyLocation,
      estimatedTime,
      features: {
        cancellationTime, firstName, lastLocationTime, lastName, polyline, sessionId,
      },
    })),
  ];
  events.sort((a, b) => new Date(a.estimatedTime) - new Date(b.estimatedTime));

  return (
    <MapAdmin
      ride={ride}
      sessions={sessions}
      events={events}
      emergencyLocations={emergencyLocations}
      debugTrack={debugTrack}
      debugLocations={debugLocations}
      authStatus={authStatus}
    />
  );
}

AuthenticatedAdminPage.propTypes = {
  rideId: PropTypes.string,
  sharingToken: PropTypes.string,
  authStatus: PropTypes.node,
  sessionIds: PropTypes.arrayOf(PropTypes.string),
};

AuthenticatedAdminPage.defaultProps = {
  rideId: null,
  sharingToken: null,
  authStatus: null,
  sessionIds: [],
};

function MapAdminPage(props) {
  const { rideIdParam, sharingTokenParam, sessionIdsParam } = props;
  const isClient = useIsClient();

  if (!isClient) {
    return null;
  }

  let rideId = rideIdParam && rideIdParam.trim();
  if (!rideId || !rideId.match(/^[0-9a-f-]{36}$/i)) {
    rideId = null;
  }
  const sharingToken = sharingTokenParam && sharingTokenParam.trim();
  const sessionIds = sessionIdsParam ? sessionIdsParam.split(',').filter((sessionId) => sessionId.length === 36) : [];

  return (
    <AdminRequired>
      {(authStatus) => {
        if (!rideId && !sharingToken && !sessionIds.length) {
          return (
            <>
              <RideIdForm rideId={rideIdParam} sharingToken={sharingTokenParam} />
              <RideError />
            </>
          );
        }

        return (
          <AuthenticatedAdminPage
            rideId={rideId}
            authStatus={authStatus}
            sharingToken={sharingToken}
            sessionIds={sessionIds}
          />
        );
      }}
    </AdminRequired>
  );
}

MapAdminPage.propTypes = {
  rideIdParam: PropTypes.string,
  sharingTokenParam: PropTypes.string,
  sessionIdsParam: PropTypes.string,
};

MapAdminPage.defaultProps = {
  rideIdParam: null,
  sharingTokenParam: null,
  sessionIdsParam: null,
};

export default MapAdminPage;
