import {
  useEffect, useRef, useState, useLayoutEffect,
} from 'react';

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return undefined;
  }, [delay]);
}

export function useIsClient() { // Detects server-side rendering, does not break hydration.
  const [isClient, setClient] = useState(false);
  useEffect(() => {
    setClient(true);
  }, []);

  return isClient;
}

export const useLayoutEffectWithoutSsrWarning = typeof window !== 'undefined'
  ? useLayoutEffect : useEffect; // https://blog.chetan-verma.in/uselayouteffect-and-the-ssr-warning
