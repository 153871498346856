import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import LogoAndGradient from './LogoAndGradient';

const LogoContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  pointer-events: none;
`;
const BottomContainer = styled.div`
  pointer-events: none;
  position: fixed;
  bottom: .4%;
  right: 0;
  left: 0;
  margin: auto;
  width: 99%;
  box-sizing: border-box;
  z-index: 10; /* Above legend and attribution of maplibre-gl. */
  @media (min-width: 800px) {
    bottom: 10px;
    right: 10px;
    left: auto;
    top: auto;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;
const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
  margin-bottom: 10px;
  & * {
    pointer-events: auto;
  }
  @media (min-width: 800px) {
    margin-right: 15px;
    margin-bottom: 0;
  }
`;
const SheetContainer = styled.div`
  & * {
    pointer-events: auto;
  }
  @media (min-width: 800px) {
    width: 360px;
  }
`;

export default function RideViewLayout(props) {
  const {
    map,
    actionButtons,
    details,
  } = props;

  return (
    <>
      <LogoContainer>
        <LogoAndGradient />
      </LogoContainer>
      {map}
      <BottomContainer>
        <ActionContainer>
          {actionButtons}
        </ActionContainer>
        <SheetContainer>
          {details}
        </SheetContainer>
      </BottomContainer>
    </>
  );
}

RideViewLayout.propTypes = {
  map: PropTypes.node.isRequired,
  actionButtons: PropTypes.node,
  details: PropTypes.node.isRequired,
};

RideViewLayout.defaultProps = {
  actionButtons: null,
};
