import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { getAuth, signOut } from 'firebase/auth';

import { useAuthContext } from 'components/AuthContext';
import useFirebaseAuthListener from 'components/AuthContext/useFirebaseAuthListener';
import LoginForm from './LoginForm';

const Container = styled.div`
  padding: 20px;
`;
const PaddedText = styled.div`
  padding: 5px 0;
  font-size: smaller;
  text-align: right;
`;

function AdminRequired({ children }) {
  useFirebaseAuthListener();
  const { t } = useTranslation();
  const { user, ready } = useAuthContext();

  if (!ready) {
    return <p>{t('loading_pending')}</p>;
  }

  if (!user) {
    return <LoginForm />;
  }

  const authStatus = (
    <PaddedText>
      {`${user.email || '(undefined)'} `}
      <button type="button" onClick={() => signOut(getAuth())}>
        {t('login_signout_button')}
      </button>
    </PaddedText>
  );

  if (user.isAdmin) {
    return children(authStatus);
  }

  return (
    <Container>
      <p>You need an admin account.</p>
      <br />
      {authStatus}
    </Container>
  );
}

AdminRequired.propTypes = {
  children: PropTypes.func.isRequired,
};

export default AdminRequired;
