import { useEffect } from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import polylineLib from '@mapbox/polyline';

import endMarker from './end-marker.svg';
import { mapgl, emptyGeojson } from '../mapUtils';
import arrow from './arrow.png';

const ripple = keyframes`
  0%, 35% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(0.5);
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
`;

export const MarkerStyles = styled.span`
  .marker-pause {
    display: flex;
    justify-content: center;
    align-items: center;s
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    padding: 0;
    background: rgba(255, 255, 255, 0.612189);
    border: 1px solid #F9F9F9;
    border-radius: 50%;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      background: #FFF;
      opacity: 0.75;
      border-radius: 50%;
      box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.192007);
      font-weight: 600;
      font-size: 13.5828px;
    }
  }

  .marker-start {
    background: #2BE593;
    width: 34px;
    height: 34px;
    box-sizing: border-box;
    border-radius: 50%;
    border: solid 10px #ffffff;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.26);
  }

  .marker-end {
    background: url(${endMarker}) no-repeat;
    background-size: cover;
    width: 57px;
    height: 57px;
  }

  .marker-not-stopped {
    width: 100px;
    height: 100px;
    .point {
      position: absolute;
      top: 33px;
      left: 33px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 34px;
      height: 34px;
      background: #FFF;
      border-radius: 50%;
    }
    .inner {
      box-sizing: border-box;
      width: 14px;
      height: 14px;
      box-shadow: 0px 1px 8px #B3B3B3;
      border-radius: 50%;
    }
    .inner.active {
      background: #FF6158;
    }
    .inner.paused {
      background: #FFC402;
    }
    .circle {
      position: absolute;
      width: 100px;
      height: 100px;
      background: radial-gradient(29.10px at 50.24% 50.54%, rgba(205, 206, 212, 0.0001) 0%, #EFF1F7 100%);
      border-radius: 50%;
      mix-blend-mode: normal;
      opacity: 0.71;
      animation: ${ripple} 2s ease-out infinite;
    }
    .circle.active {
      border: 1px solid #FF6158;
    }
    .circle.paused {
      border: 1px solid #FFC402;
    }
  }
`;

export function useMapRidePolyline(map, { polyline, followRider, userMoveInitiated }) {
  const SOURCE_ID = 'polyline_source';
  const LAYER_POLYLINE_ID = 'polyline_layer';
  const LAYER_POLYLINE_ARROW_ID = 'polyline_arrow_layer';
  const IMAGE_ID = 'polyline_arrow';

  useEffect(() => {
    if (!map) {
      return undefined; // No Cleanup callback.
    }

    if (!map.hasImage(IMAGE_ID)) {
      map.loadImage(arrow).then((image) => {
        if (!map.hasImage(IMAGE_ID)) {
          map.addImage(IMAGE_ID, image.data, { sdf: true });
        }
      }).catch(console.error); // eslint-disable-line no-console
    }

    map.addSource(SOURCE_ID, {
      type: 'geojson',
      data: emptyGeojson,
    });

    map.addLayer({
      id: LAYER_POLYLINE_ID,
      source: SOURCE_ID,
      type: 'line',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#fa455c',
        'line-width': 6,
        'line-opacity': 0.5,
      },
    });

    map.addLayer({
      id: LAYER_POLYLINE_ARROW_ID,
      source: SOURCE_ID,
      type: 'symbol',
      layout: {
        'icon-image': IMAGE_ID,
        'symbol-placement': 'line',
        'icon-size': 0.5, // Image density 2x.
        'symbol-spacing': 30,
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
      },
      paint: {
        'icon-opacity': 0.7,
        'icon-color': '#f00', // Only for SDF icons.
      },
    });

    return () => {
      map.removeLayer(LAYER_POLYLINE_ID);
      map.removeLayer(LAYER_POLYLINE_ARROW_ID);
      map.removeSource(SOURCE_ID);
    };
  }, [map]);

  useEffect(() => {
    if (!map) {
      return;
    }

    const source = map.getSource(SOURCE_ID);
    const geojson = polylineLib.toGeoJSON(polyline);
    source.setData(geojson);
  }, [map, polyline]);

  useEffect(() => {
    if (!map || !polyline || !followRider) {
      return;
    }

    // Center map around new last polyline point.
    const geojson = polylineLib.toGeoJSON(polyline);
    const lastPoint = geojson.coordinates[geojson.coordinates.length - 1];
    map.easeTo({ center: lastPoint });
  }, [map, polyline, followRider]);

  useEffect(() => {
    if (map && userMoveInitiated) {
      map.on('zoomstart', userMoveInitiated);
      map.on('dragstart', userMoveInitiated);
    }
  }, [map, userMoveInitiated]);
}

export function useMapPauseMarkers(map, pauses) {
  useEffect(() => {
    if (!map) {
      return undefined; // No Cleanup callback.
    }

    const markers = [];
    pauses.forEach((pause, n) => {
      const element = document.createElement('div');
      element.innerHTML = `<div>${n + 1}</div>`;
      element.className = 'marker-pause';

      if (pause.lastLocation) {
        markers.push(new mapgl.Marker({ element })
          .setLngLat([pause.lastLocation.longitude, pause.lastLocation.latitude])
          .addTo(map));
      }
    });

    return () => {
      markers.forEach((marker) => marker.remove());
    };
  }, [map, JSON.stringify(pauses)]);
}

export function useMapStartMarker(map, coords, { transparentEnds, hide } = {}) {
  useEffect(() => {
    if (!map || !coords || hide) {
      return undefined; // No Cleanup callback.
    }

    const element = document.createElement('div');
    element.className = 'marker-start';
    const marker = new mapgl.Marker({ element })
      .setLngLat(coords)
      .addTo(map);
    if (transparentEnds) {
      element.style.opacity = 0.4;
    }

    return () => {
      marker.remove();
    };
  }, [map, JSON.stringify(coords), hide]);
}

export function useMapRiderPositionMarker(map, coords, { rideState, transparentEnds, hide } = {}) {
  useEffect(() => {
    if (!map || !coords || hide) {
      return undefined; // No Cleanup callback.
    }

    let marker;
    const element = document.createElement('div');
    if (rideState === 'RIDE_STOPPED') {
      element.className = 'marker-end';
      marker = new mapgl.Marker({ element, offset: [0, 10] });
    } else {
      const isActive = rideState === 'RIDE_ACTIVE';
      element.innerHTML = `
      <div class="point">
        <div class="inner ${isActive ? 'active' : 'paused'}"></div>
      </div>
      <div class="circle ${isActive ? 'active' : 'paused'}"></div>
      `;
      element.className = 'marker-not-stopped';
      marker = new mapgl.Marker({ element });
    }

    marker.setLngLat(coords)
      .addTo(map);
    if (transparentEnds) {
      element.style.opacity = 0.3;
    }

    return () => {
      marker.remove();
    };
  }, [map, JSON.stringify(coords), rideState, hide]);
}
