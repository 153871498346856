import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

import config from 'config';

export function initAuth() {
  initializeApp(config.firebase.clientConfig);

  getAuth().useDeviceLanguage();
}

export default null;
