import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const colorByLevel = (batteryPercent) => {
  if (batteryPercent > 35) {
    return '#2BE593';
  }
  if (batteryPercent > 5) {
    return '#FFC402';
  }
  return '#FF6158';
};

const BatteryContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${(props) => props.color || '#2BE593'};
`;
const Battery = styled.div`
  position: relative;
  top: 1px;
  width: 8px;
  height: 14px;
  border: 1px solid white;
  border-radius: 3px;
  &:after {
    content: '';
    position: absolute;
    top: -4px;
    left: 2px;
    width: 4px;
    height: 2px;
    background: white;
    border-radius: 10px 10px 0px 0px;
  }
`;
const LevelContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-flow: column-reverse;
  overflow: hidden;
  box-sizing: border-box;
  height: 100%;
  border-radius: 3px;
  padding: 1px;
`;
const Level = styled.div`
  width: 100%;
  height: ${(props) => props.batteryPercent}%;
  background: white;
  border-radius: 1px;
`;

function BatteryIcon({ batteryPercent }) {
  return (
    <BatteryContainer color={colorByLevel(batteryPercent)} aria-hidden>
      <Battery>
        <LevelContainer>
          <Level batteryPercent={batteryPercent} />
        </LevelContainer>
      </Battery>
    </BatteryContainer>
  );
}

BatteryIcon.propTypes = {
  batteryPercent: PropTypes.number.isRequired,
};

BatteryIcon.defaultProps = {
};

export default BatteryIcon;
