import React from 'react';
import PropTypes from 'prop-types';
import ReverseGeocoding from 'components/ReverseGeocoding';
import LocationIcon from './LocationIcon';
import ArrivalIcon from './ArrivalIcon';

export default function LocationDetails(props) {
  const {
    isActive, stateColor, coordinates,
  } = props;

  return (
    <>
      {isActive ? <LocationIcon color={stateColor} /> : <ArrivalIcon />}
      <ReverseGeocoding coordinates={coordinates} />
    </>
  );
}

LocationDetails.propTypes = {
  isActive: PropTypes.bool,
  stateColor: PropTypes.string,
  coordinates: PropTypes.arrayOf(PropTypes.number).isRequired,
};

LocationDetails.defaultProps = {
  isActive: true,
  stateColor: null,
};
