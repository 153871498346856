import { useEffect } from 'react';
import { onIdTokenChanged, getAuth, getIdTokenResult } from 'firebase/auth';
import { initAuth } from 'services/firebase';

import { useAuthContext } from './index';

initAuth();

let authTimeout = null;

// The reason that we have a dedicated component to listen for Firebase Auth is
// that we only want to include the big Firebase Auth package on authenticated routes.
export default function useFirebaseAuthListener() {
  const { setFirebaseUser } = useAuthContext();

  useEffect(() => {
    if (!setFirebaseUser) {
      return null;
    }

    const refreshToken = async (firebaseUser) => {
      if (authTimeout) {
        clearTimeout(authTimeout);
        authTimeout = null;
      }

      if (!firebaseUser) {
        setFirebaseUser(null);
        return;
      }
      const { token, claims, expirationTime } = await getIdTokenResult(firebaseUser);
      setFirebaseUser({
        uid: firebaseUser.uid,
        email: firebaseUser.email,
        emailVerified: firebaseUser.emailVerified,
        authToken: token,
        isAdmin: claims.email_verified && !!claims.email.match('@liberty-rider.com$'),
      });

      const expiresIn = new Date(expirationTime).getTime() - new Date().getTime() - 60000; /* Time
      left in seconds, minus 1 minute to make sure we refresh token in time. */
      authTimeout = setTimeout(async () => {
        await getIdTokenResult(firebaseUser, true);
        refreshToken(firebaseUser);
      }, expiresIn);
    };

    return onIdTokenChanged(getAuth(), async (firebaseUser) => {
      refreshToken(firebaseUser);
    });
  }, [setFirebaseUser]);
}
