import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { formatDateTime } from 'services/utils';

const Container = styled.div`
  margin: 50px;
`;

export default function ExpiredToken({ emergencyId, expiredAt }) {
  const { i18n: { language } } = useTranslation();

  return (
    <Container>
      The link expired.
      <br />
      <br />
      expiredAt =
      {' '}
      {formatDateTime(new Date(expiredAt), language)}
      <br />
      <br />
      emergencyId =
      {' '}
      {emergencyId}
    </Container>
  );
}

ExpiredToken.propTypes = {
  emergencyId: PropTypes.string.isRequired,
  expiredAt: PropTypes.number.isRequired,
};
