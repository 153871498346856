import styled from '@emotion/styled';

export default null;

export const Button = styled.button`
  user-select: none;
  height: 40px;
  padding: 0 25px;
  min-width: 120px;
  width: 165px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  border: none;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  cursor: pointer;
  &:hover {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.15);
  }
  &:active {
    opacity: 0.8;
  }
  &:focus { /* Tab-selection, and after being clicked. */
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.15);
    outline: none;
  }
`;
