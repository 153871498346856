import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

const Container = styled.div`
  padding: 20px;
`;

export default function LoginForm() {
  const { t } = useTranslation();
  const [error, setError] = useState(null);

  async function startAuthentication() {
    try {
      await signInWithPopup(getAuth(), new GoogleAuthProvider());
    } catch (err) {
      setError(err);
    }
  }

  return (
    <Container>
      {t('login_required_page')}
      <br />
      <br />
      <button type="button" onClick={() => startAuthentication()}>
        {t('auth_continue_with_button', { provider: 'Google' })}
      </button>
      <br />
      <br />
      {error && t('signin_error_unknown', { error: (error.code || error.message || error) })}
    </Container>
  );
}
