import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import BatteryIcon from './BatteryIcon';

const BatteryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  height: 72px;
`;
const BatteryValue = styled.div`
  font-size: 16px;
  line-height: 21px;
`;
const BatteryText = styled.div`
  font-size: 14px;
  line-height: 21px;
  opacity: 0.6;
`;

function BatteryInfo({ currentBatteryLevel }) {
  const { t } = useTranslation();

  const batteryPercent = Math.max(0, Math.min(100, Math.round(currentBatteryLevel * 100)));

  return (
    <BatteryContainer>
      <BatteryIcon batteryPercent={batteryPercent} />
      <BatteryValue>{`${batteryPercent}%`}</BatteryValue>
      <BatteryText>{t('battery')}</BatteryText>
    </BatteryContainer>
  );
}

BatteryInfo.propTypes = {
  currentBatteryLevel: PropTypes.number.isRequired,
};

BatteryInfo.defaultProps = {};

export default BatteryInfo;
