import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinner';
import config from 'config';

const Street = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  opacity: 0.6;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
`;
const Locality = styled.div`
  font-size: 16px;
  line-height: 21px;
  min-height: 21px;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
`;

const getJawgApiUrl = ({
  baseUrl, token, lat, lng, layers = 'locality,street',
}) => `${baseUrl}?access-token=${token}&point.lat=${lat}&point.lon=${lng}&layers=${layers}&size=1`;

export default function ReverseGeocoding(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [street, setStreet] = useState(null);
  const [locality, setLocality] = useState(null);

  const getLocation = async (coordinates) => {
    const lat = coordinates[1];
    const lng = coordinates[0];

    setStreet(null);
    setLocality(null);

    try {
      const res = await fetch(getJawgApiUrl({
        baseUrl: 'https://maps-cloudfront.liberty-rider.com' // Caching proxy for api.jawg.io
          + '/places/v1/reverse',
        token: config.jawgMaps.key,
        lat,
        lng,
      }));
      const json = await res.json();

      const features = json.features || [];
      const feature = features[0] || {};
      const properties = feature.properties || {};
      setStreet(properties.street || null);
      setLocality(properties.locality || null);
      setIsLoading(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const { coordinates } = props;
    getLocation(coordinates);
  }, []);

  return (
    <>
      {locality && <Locality>{locality}</Locality>}
      {street && <Street>{street}</Street>}
      {isLoading && <Spinner />}
    </>
  );
}

ReverseGeocoding.propTypes = {
  coordinates: PropTypes.arrayOf(PropTypes.number).isRequired,
};
