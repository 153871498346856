import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';

import PageLoading from 'components/PageLoading';
import LogoAndGradient from 'components/RideViewLayout/LogoAndGradient';
import RideError from 'components/RideError';
import RideSharingMap from './RideSharingMap';
import RideMeta from './RideMeta';

const RIDE_BY_RIDE_SHARING_TOKEN = gql`
  query rideSharingPageBySharingToken($token: String!, $languageCode: String) {
    ride: rideBySharingToken(sharingToken: $token) {
      id
      ...RideMetaFragment
    }
  }
  ${RideMeta.fragments.rideMeta}
`;

const RIDE_BY_USER_SHARING_TOKEN = gql`
  query rideSharingPageByUserCurrentRideSharingToken($token: String!, $languageCode: String) {
    ride: rideByUserCurrentRideSharingToken(token: $token) {
      id
      ...RideMetaFragment
    }
  }
  ${RideMeta.fragments.rideMeta}
`;

function RideSharingPage({ sharingToken, userCurrentRideSharingToken }) {
  const { i18n: { language } } = useTranslation();
  const getRideQuery = sharingToken ? RIDE_BY_RIDE_SHARING_TOKEN : RIDE_BY_USER_SHARING_TOKEN;
  const { loading, error, data } = useQuery(getRideQuery, {
    variables: {
      token: sharingToken || userCurrentRideSharingToken, languageCode: language,
    },
  });

  if (loading && !data) {
    return <PageLoading />;
  }
  if (error) {
    return (
      <>
        <LogoAndGradient />
        <RideError />
      </>
    );
  }

  const { ride } = data;

  return (
    <>
      {ride && <RideMeta ride={ride} />}
      <RideSharingMap
        sharingToken={sharingToken}
        userCurrentRideSharingToken={userCurrentRideSharingToken}
      />
    </>
  );
}

RideSharingPage.propTypes = {
  sharingToken: PropTypes.string,
  userCurrentRideSharingToken: PropTypes.string,
};

RideSharingPage.defaultProps = {
  sharingToken: null,
  userCurrentRideSharingToken: null,
};

export default RideSharingPage;
