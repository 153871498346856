import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useTranslation } from 'react-i18next';
import { Button } from 'components/StyledButtons';
import LocationDetails from './LocationDetails';

const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 93px;
`;

export default function Location(props) {
  const {
    stateColor, isActive, coordinates,
  } = props;

  const { t } = useTranslation();
  const [showLocation, setShowLocation] = useState(false);

  return (
    <LocationContainer>
      {showLocation ? (
        <LocationDetails coordinates={coordinates} stateColor={stateColor} isActive={isActive} />
      ) : (
        <Button type="button" onClick={() => setShowLocation(true)}>{t('show_location_button')}</Button>
      )}
    </LocationContainer>
  );
}

Location.propTypes = {
  coordinates: PropTypes.arrayOf(PropTypes.number).isRequired,
  stateColor: PropTypes.string,
  isActive: PropTypes.bool,
};

Location.defaultProps = {
  stateColor: null,
  isActive: true,
};
