import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Open = styled.button``;
const Popup = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 10px;
`;
const Close = styled.button`
  position: absolute;
  right: 25px;
  top: 15px;
  width: 40px;
  height: 40px;
  font-weight: bold;
  font-size: 22px;
`;
const Textarea = styled.textarea`
  font-family: monospace;
  white-space: pre;
  width: 100%;
  height: 100%;
  overflow: auto;
  font-size: smaller;
`;

function JsonPopup({ title, json }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Open onClick={() => setIsOpen(true)}>{title}</Open>
      {isOpen && (
        <Popup>
          <Close onClick={() => setIsOpen(false)}>&times;</Close>
          <Textarea value={JSON.stringify(json, null, '   ')} readOnly />
        </Popup>
      )}
    </>
  );
}

JsonPopup.propTypes = {
  title: PropTypes.string.isRequired,
  json: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired, // eslint-disable-line react/forbid-prop-types
};

JsonPopup.defaultProps = {
};

export default JsonPopup;
