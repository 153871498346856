import { useEffect } from 'react';
import styled from '@emotion/styled';

import { mapgl, setMapLayerData } from 'components/RideMap/mapUtils';
import { createGeoJSONCircle } from 'services/utils';
import emergencyMarker from './emergency-marker.png';

export const EmergencyMapStyle = styled.span`
  .sos-marker {
    background: url(${emergencyMarker}) no-repeat;
    background-size: cover;
    width: 60px;
    height: 55px;
  }
`;

export default function useMapEmergency(map, emergencyLocations, { transparent = false } = {}) {
  const LAYER_ID = 'emergencycircle_layer';
  const SOURCE_ID = 'emergencycircle_source';

  const lngLats = emergencyLocations.map(
    (emergencyLocation) => [emergencyLocation.longitude, emergencyLocation.latitude],
  );

  useEffect(() => {
    if (!map || !lngLats.length) {
      return undefined; // No Cleanup callback.
    }

    const markers = lngLats.map((lngLat) => {
      const element = document.createElement('div');
      element.className = 'sos-marker';
      if (transparent) {
        element.style.opacity = 0.5;
      }

      return new mapgl.Marker({ element, offset: [0, -20] })
        .setLngLat(lngLat)
        .addTo(map);
    });

    const geojson = {
      type: 'FeatureCollection',
      features: lngLats.map((lngLat) => createGeoJSONCircle(lngLat, 100)),
    };

    setMapLayerData({
      map,
      layerId: LAYER_ID,
      sourceId: SOURCE_ID,
      style: {
        type: 'fill',
        paint: {
          'fill-color': '#D86950',
          'fill-opacity': transparent ? 0.2 : 0.5,
        },
      },
      geojson,
    });

    return () => {
      markers.forEach((marker) => marker.remove());
      map.removeLayer(LAYER_ID);
    };
  }, [map, JSON.stringify(lngLats)]);
}
