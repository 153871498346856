import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f8f4f0; /* Background color of jawg-sunny. */
  width: 100%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 100vh;
`;
const MainText = styled.div`
  font-size: 27px;
  font-weight: 800;
  line-height: 1.09;
  text-align: center;
  color: #000000;
`;
const SubText = styled.div`
  font-size: 15px;
  line-height: 1.47;
  text-align: center;
  color: #000000;
`;

export default function WaitingForSignal() {
  const { t } = useTranslation();

  return (
    <Container>
      <MainText>{t('waiting_no_location')}</MainText>
      <SubText>{t('please_retry_later')}</SubText>
    </Container>
  );
}
