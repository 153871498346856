import React, { forwardRef } from 'react';
import polylineLib from '@mapbox/polyline';

import useMapBase, { BaseContainer } from './useMapBase';
import {
  MarkerStyles,
  useMapPauseMarkers,
  useMapStartMarker,
  useMapRiderPositionMarker,
  useMapRidePolyline,
} from './mapRideHooks';
import { getBounds } from './mapUtils';

export const RideMapContainer = forwardRef((props, ref) => (
  <MarkerStyles>
    <BaseContainer ref={ref} />
  </MarkerStyles>
));

export function useRideMap(options) {
  const {
    initialZoom,
    detailedPolyline,
    state: rideState = null,
    pauses = [],
    displayScale = false,
    followRider = false,
    userMoveInitiated = false,
    transparentEnds = false,
    fitToPolyline = false,
    hideStart = false,
    hideStop = false,
  } = options;

  const { coordinates } = polylineLib.toGeoJSON(detailedPolyline || '');
  const lastPosition = coordinates[coordinates.length - 1];

  const initialBounds = fitToPolyline && (coordinates.length >= 10) && getBounds(coordinates);

  const base = useMapBase({
    displayScale, initialZoom, initialCenter: lastPosition, initialBounds,
  });
  const map = base[1];

  useMapRidePolyline(map, {
    polyline: detailedPolyline || '',
    followRider,
    userMoveInitiated,
  });
  useMapStartMarker(map, coordinates[0], { transparentEnds, hide: hideStart });
  useMapPauseMarkers(map, pauses);
  useMapRiderPositionMarker(map, lastPosition, { rideState, transparentEnds, hide: hideStop });

  return base;
}
