import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import RideViewLayout from 'components/RideViewLayout';
import WaitingForSignal from 'components/WaitingForSignal';
import { RideMapContainer, useRideMap } from 'components/RideMap';
import { formatDateTime } from 'services/utils';
import BottomSheet from 'components/BottomSheet';
import AlertStatus from './AlertStatus';
import ExpiredToken from './ExpiredToken';
import useMapEmergency, { EmergencyMapStyle } from './useMapEmergency';

const P = styled.p`
  margin-bottom: 5px;
`;
const BigVehicle = styled.div`
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  border: solid 3px red;
  padding: 5px 0;
  color: #a00;
  margin-bottom: 10px;
`;
const Main = styled.div`
  padding: 10px;
`;
const Secondary = styled.div`
  padding: 10px;
  font-size: smaller;
`;

function MainCockpit({ emergency }) {
  const {
    emergencyId,
    estimatedTime,
    cancellationTime,
    emergencyLocation,
    polyline,
    lastLocationTime,
    firstName,
    lastName,
    vehicleProfile,
  } = emergency;

  const { t, i18n: { language } } = useTranslation();
  const [mapRef, map] = useRideMap({
    detailedPolyline: polyline,
    displayScale: true,
    followRider: true,
    initialZoom: 15,
    hideStart: true, // The start marker is confusing for IMA, and meaningless (only 10 points).
  });
  useMapEmergency(map, [emergencyLocation]);

  let translatedVehicle;
  if (!vehicleProfile || vehicleProfile === 'MOTORCYCLE') {
    translatedVehicle = t('vehicle_type_motorcycle');
  } else if (vehicleProfile === 'CAR') {
    translatedVehicle = t('vehicle_type_car');
  } else if (vehicleProfile === 'BICYCLE') {
    translatedVehicle = t('vehicle_type_bicycle');
  } else {
    translatedVehicle = `${vehicleProfile} (?)`;
  }

  return (
    <>
      <AlertStatus cancellationTime={cancellationTime} />
      <RideViewLayout
        map={polyline
          ? <EmergencyMapStyle><RideMapContainer ref={mapRef} /></EmergencyMapStyle>
          : <WaitingForSignal />}
        details={(
          <BottomSheet
            mainContent={(
              <Main>
                {(!vehicleProfile || vehicleProfile === 'MOTORCYCLE')
                  ? (
                    <P>
                      {t('form_vehicle_label')}
                      {' : '}
                      {translatedVehicle}
                    </P>
                  ) : (
                    <P>
                      <BigVehicle>
                        {t('form_vehicle_label')}
                        {' : '}
                        {translatedVehicle.toUpperCase()}
                      </BigVehicle>
                    </P>
                  )}
                <P>
                  {t('form_first_name_label')}
                  {' : '}
                  {firstName}
                </P>
                <P>
                  {t('form_last_name_label')}
                  {' : '}
                  {lastName}
                </P>
                <P>{estimatedTime ? `${t('accident_time_label')} : ${formatDateTime(new Date(estimatedTime), language)}` : null}</P>
              </Main>
            )}
            secondaryContent={(
              <Secondary>
                <div>
                  <P>{`ID : ${emergencyId}`}</P>
                  <P>{lastLocationTime ? `${t('accident_position_time_label')} : ${formatDateTime(new Date(lastLocationTime), language)}` : null}</P>
                </div>
              </Secondary>
            )}
          />
        )}
      />
    </>
  );
}

MainCockpit.propTypes = {
  emergency: PropTypes.shape({
    emergencyId: PropTypes.string,
    estimatedTime: PropTypes.string,
    cancellationTime: PropTypes.string,
    emergencyLocation: PropTypes.shape({}),
    polyline: PropTypes.string,
    lastLocationTime: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    vehicleProfile: PropTypes.string,
  }).isRequired,
};

export default function ImaCockpit({ emergency }) {
  if (emergency.expiredAt) {
    return <ExpiredToken expiredAt={emergency.expiredAt} emergencyId={emergency.emergencyId} />;
  }

  return <MainCockpit emergency={emergency} />;
}

ImaCockpit.propTypes = {
  emergency: PropTypes.shape({
    expiredAt: PropTypes.number,
    emergencyId: PropTypes.string,
  }).isRequired,
};
