import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import PageLoading from 'components/PageLoading';
import ImaCockpit from 'components/ImaCockpit';
import useFirebaseAuthListener from 'components/AuthContext/useFirebaseAuthListener';
import useEmergencyPollingV1 from './requests';

const Error = styled.p`
  padding: 50px;
`;

function ImaCockpitPage(props) {
  const { token } = props;
  const { t } = useTranslation();
  useFirebaseAuthListener();
  const { loading, data, error } = useEmergencyPollingV1(token);

  if (loading) {
    return <PageLoading />;
  }
  if (error || !data) {
    return (
      <Error>
        {t('loading_error')}
        <br />
        <br />
        {error.toString()}
      </Error>
    );
  }

  return (
    <ImaCockpit emergency={data} />
  );
}

ImaCockpitPage.propTypes = {
  token: PropTypes.string.isRequired,
};

export default ImaCockpitPage;
