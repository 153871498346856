import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import config from 'config';

// FIXME: add a refresh button
function AdminLinks({
  rideId, userId, sharingToken, storageUrls,
}) {
  return (
    <>
      {sharingToken && (
        <>
          <Link to={`/fr/p/${sharingToken}`}>
            Sharing
          </Link>
          {' - '}
        </>
      )}
      {rideId && (
        <a
          href={`${config.forestUrlRide}${rideId}/details`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Forest Ride
        </a>
      )}
      {userId && (
        <>
          {' - '}
          <a
            href={`${config.forestUrlUser}${userId}/details`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Forest User
          </a>
        </>
      )}
      {rideId && <br />}
      {'Storage: '}
      {storageUrls.map(([label, storageUrl]) => (
        <React.Fragment key={storageUrl}>
          <a href={storageUrl}>{label}</a>
          {' '}
        </React.Fragment>
      ))}
      <br />
    </>
  );
}

AdminLinks.propTypes = {
  rideId: PropTypes.string,
  userId: PropTypes.string,
  sharingToken: PropTypes.string,
  storageUrls: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
};

AdminLinks.defaultProps = {
  rideId: null,
  userId: null,
  sharingToken: null,
};

export default AdminLinks;
