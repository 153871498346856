import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ProfilePicture from 'components/ProfilePicture';

const Container = styled.div`
  display: flex;
`;
const Button = styled.div`
  background: #FFFFFF;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  display: flex;
  height: 40px;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  flex-basis: auto;
  padding: 0 8px;
  cursor: pointer;
`;
const Text = styled.div`
  margin: 10px;
`;

const PROFILE_SIZE = 25;

export default function RiderCenterButton(props) {
  const { t } = useTranslation();
  const {
    onClick,
    ride: {
      user,
    },
  } = props;

  return (
    <Container>
      <Button onClick={onClick}>
        <ProfilePicture profilePictureUrl={user.profilePictureUrl} size={PROFILE_SIZE} />
        <Text>{t('follow_rider_button')}</Text>
      </Button>
    </Container>
  );
}

RiderCenterButton.propTypes = {
  onClick: PropTypes.func,
  ride: PropTypes.shape({
    user: PropTypes.shape({
      profilePictureUrl: PropTypes.string,
    }),
  }).isRequired,
};

RiderCenterButton.defaultProps = {
  onClick: null,
};
