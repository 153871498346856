import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { formatDateTime } from 'services/utils';

const Container = styled.div`
  position: fixed;
  top: 10%;
  right: 0;
  left: 0;
  margin: auto;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 300px;
  border-radius: 8px;
  background: green;
  z-index: 10;
  opacity: 0.9;
`;
const Text = styled.div`
  color: white;
  font-size: 19px;
`;

function ImaAlertStatus({ cancellationTime }) {
  const { t, i18n: { language } } = useTranslation();

  if (cancellationTime) {
    return (
      <Container>
        <Text>{t('accident_alert_cancelled_title')}</Text>
        <Text>{t('accident_alert_cancelled_description', { cancellation_datetime: formatDateTime(cancellationTime, language) })}</Text>
      </Container>
    );
  }

  return null;
}

ImaAlertStatus.propTypes = {
  cancellationTime: PropTypes.string,
};

ImaAlertStatus.defaultProps = {
  cancellationTime: null,
};

export default ImaAlertStatus;
