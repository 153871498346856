import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import styled from '@emotion/styled';
import polylineLib from '@mapbox/polyline';
import { useTranslation } from 'react-i18next';

import BottomSheet from 'components/BottomSheet';
import { formatDistance, formatSeconds, formatDateTime } from 'services/utils';

import ProfilePicture from 'components/ProfilePicture';
import Location from './Location';
import Battery from './Battery';
import Status from './Status';

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
`;
const HeaderList = styled.div`
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  margin-left: 10px;
  flex: 1;
`;
const Username = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
`;
const GrayBody = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 10px;
  background: #F0F0F5;
`;
const Stats = styled.div`
  padding: 10px;
  font-size: 16px;
`;

const COLOR_BY_STATE = {
  RIDE_ACTIVE: '#FF6158',
  RIDE_PAUSED: '#FFC402',
  RIDE_STOPPED: '#2BE593',
};

function extractApproximateCoordinates(geojson) {
  if (!geojson) {
    return null;
  }

  // We don't want the location to refresh too fast because reverse geocoding is expensive.
  // So we only update every 100 points.
  const REFRESH_RATE = 100;
  const { length } = geojson.coordinates;
  const offset = length - 1;
  return geojson.coordinates[Math.round(REFRESH_RATE * Math.floor(offset / REFRESH_RATE))];
}

const PROFILE_SIZE = 55;

function RideDetails(props) {
  const { t, i18n: { language } } = useTranslation();

  const {
    breakpointHeight,
    hideLocation,
    children,
    ride,
    ride: {
      detailedPolyline,
      currentBatteryLevel,
      user,
      state: rideState,
      duration,
      distance,
      totalPausesDuration,
      startTime,
    },
  } = props;

  const geojson = detailedPolyline ? polylineLib.toGeoJSON(detailedPolyline) : null;
  const coordinates = extractApproximateCoordinates(geojson);
  const isActive = rideState !== 'RIDE_STOPPED';
  let fullName = `${(user && user.firstName) || ''} ${(user && user.lastName) || ''}`;
  if (fullName === ' ') {
    fullName = t('anonymous');
  }

  return (
    <BottomSheet
      breakpointHeight={breakpointHeight}
      mainContent={(
        <Header>
          <ProfilePicture profilePictureUrl={user && user.profilePictureUrl} size={PROFILE_SIZE} />
          <HeaderList>
            <Username>{fullName}</Username>
            <Status ride={ride} />
          </HeaderList>
        </Header>
      )}
      secondaryContent={(
        <>
          <GrayBody>
            {!hideLocation && coordinates && (
              <Location
                coordinates={coordinates}
                stateColor={COLOR_BY_STATE[rideState]}
                isActive={isActive}
              />
            )}
            {currentBatteryLevel && isActive
            && <Battery currentBatteryLevel={currentBatteryLevel} />}
          </GrayBody>
          <Stats>
            {t('ride_stats_started_at', { start_date: startTime ? formatDateTime(new Date(startTime), language) : '' })}
            <br />
            {t('ride_stats_total_duration', { duration: formatSeconds(duration) })}
            <br />
            {t('ride_stats_pause_duration', { duration: formatSeconds(totalPausesDuration) })}
            <br />
            {t('ride_stats_distance', { distance: formatDistance(distance) })}
          </Stats>
          {children}
        </>
      )}
    />
  );
}

RideDetails.propTypes = {
  children: PropTypes.node,
  hideLocation: PropTypes.bool,
  ride: PropTypes.shape({
    detailedPolyline: PropTypes.string,
    currentBatteryLevel: PropTypes.number,
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      profilePictureUrl: PropTypes.string,
    }),
    state: PropTypes.string,
    duration: PropTypes.number,
    distance: PropTypes.number,
    totalPausesDuration: PropTypes.number,
    startTime: PropTypes.string,
  }).isRequired,
  breakpointHeight: PropTypes.number,
};

RideDetails.defaultProps = {
  children: null,
  breakpointHeight: undefined,
  hideLocation: false,
};

RideDetails.fragments = {
  rideDetails: gql`
    fragment RideDetailsFragment on Ride {
      state
      detailedPolyline
      currentBatteryLevel
      duration
      distance
      totalPausesDuration
      startTime
      user {
        id
        firstName
        lastName
        profilePictureUrl(width: 55, height: 55) # PROFILE_SIZE
      }
      ...StatusFragment
    }
    ${Status.fragments.status}
  `,
};

export default RideDetails;
