import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Container = styled.div`
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 1px 6px rgba(0,0,0,0.3);
  padding-top: 7px;
`;
const Toggle = styled.button`
  margin: auto;
  height: 6px;
  width: 40px;
  border-radius: 3px;
  border: none;
  background: #D9D9D9;
  outline: none;
  cursor: pointer;
  display: none;
  @media (max-height: ${(props) => props.breakpointHeight}px) {
    display: block;
  }
`;
const SecondaryContainer = styled.div`
  display: block;
  @media (max-height: ${(props) => props.breakpointHeight}px) {
    display: ${(props) => (props.collapseOnSmallScreens ? 'none' : 'block')};
  }
`;

export default function BottomSheet({ mainContent, secondaryContent, breakpointHeight }) {
  const [collapseOnSmallScreens, setCollapseOnSmallScreens] = useState(true);

  function onClick(e) {
    if (secondaryContent) {
      setCollapseOnSmallScreens(!collapseOnSmallScreens);
      e.preventDefault();
    }
  }

  return (
    <Container>
      <div
        role="button"
        tabIndex={0}
        onTouchStart={onClick}
        onKeyPress={onClick}
        onMouseDown={onClick}
      >
        {secondaryContent && <Toggle breakpointHeight={breakpointHeight} />}
        {mainContent}
      </div>
      <SecondaryContainer
        collapseOnSmallScreens={collapseOnSmallScreens}
        breakpointHeight={breakpointHeight}
      >
        {secondaryContent}
      </SecondaryContainer>
    </Container>
  );
}

BottomSheet.propTypes = {
  mainContent: PropTypes.node.isRequired,
  secondaryContent: PropTypes.node,
  breakpointHeight: PropTypes.number,
};

BottomSheet.defaultProps = {
  secondaryContent: null,
  breakpointHeight: 620,
};
