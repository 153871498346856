import { useState, useEffect } from 'react';

import config from 'config';
import { useInterval } from 'services/hooks';
import { getAuthTokenForHttp } from 'services/global-auth-state';

const REFRESH_INTERVAL = 5000;

export default function useEmergencyPollingV1(token) {
  const [response, setResponse] = useState({ loading: true, data: null, error: null });

  async function fetchResponse() {
    const jwtoken = getAuthTokenForHttp();

    try {
      const res = await fetch(`${config.emergencyApiUrl}/v1/cockpit/token/${token}`, {
        // mode: 'cors',
        headers: jwtoken ? { authorization: `Bearer ${jwtoken}` } : {},
      }); // FIXME: send the token in a header for security
      const json = await res.json();

      setResponse({ loading: false, data: json.cockpit, error: json.error });
    } catch (error) {
      if (response.loading) {
        // Set error only on first load, to avoid showing transient errors later.
        setResponse({ loading: false, data: null, error });
      }
      // Polling will not stop after an error.
    }
  }

  useEffect(() => { fetchResponse(); }, [token]);
  useInterval(() => { fetchResponse(); }, REFRESH_INTERVAL);

  return response;
}
