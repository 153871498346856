import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;
const State = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: ${(props) => props.color || '#FF6158'};
`;

export default function Status(props) {
  const { t } = useTranslation();

  const LABEL_BY_STATE = {
    RIDE_ACTIVE: t('ride_state_active'),
    RIDE_PAUSED: t('ride_state_paused'),
    RIDE_STOPPED: t('ride_state_stopped'),
  };
  const COLOR_BY_STATE = {
    RIDE_ACTIVE: '#FF6158',
    RIDE_PAUSED: '#FFC402',
    RIDE_STOPPED: '#2BE593',
  };

  const { ride: { state } } = props;

  return (
    <Container>
      <State color={COLOR_BY_STATE[state]}>{LABEL_BY_STATE[state]}</State>
    </Container>
  );
}

Status.propTypes = {
  ride: PropTypes.shape({
    state: PropTypes.string,
  }).isRequired,
};

Status.defaultProps = {
};

Status.fragments = {
  status: gql`
    fragment StatusFragment on Ride {
      state
    }
  `,
};
