import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Container = styled.div`
  box-sizing: border-box;
  margin: 10px 0;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const InputText = styled.input`
  padding: 5px;
  margin-bottom: 3px;
  font-family: monospace;
`;

function RideIdForm(props) {
  const { rideId: propsRideId, sharingToken: propsSharingToken } = props;
  const [rideId, setRideId] = useState(propsRideId || '');
  const [sharingToken, setSharingToken] = useState(propsSharingToken || '');

  return (
    <Container>
      <Form>
        <InputText
          type="text"
          name="ride_id"
          placeholder="ride_id"
          value={rideId}
          onChange={(e) => {
            setRideId(e.target.value);
            setSharingToken(''); // Remove conflicting value.
          }}
          minLength={36}
          maxLength={36}
        />
        <div>
          <InputText
            type="text"
            name="sharing_token"
            placeholder="sharing_token"
            value={sharingToken}
            onChange={(e) => {
              setRideId(''); // Remove conflicting value.
              setSharingToken(e.target.value);
            }}
            minLength={8}
            maxLength={20}
          />
          &nbsp;
          <input type="submit" value="Submit" />
        </div>
      </Form>
    </Container>
  );
}

RideIdForm.propTypes = {
  rideId: PropTypes.string,
  sharingToken: PropTypes.string,
};

RideIdForm.defaultProps = {
  rideId: '',
  sharingToken: '',
};

export default RideIdForm;
