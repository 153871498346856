import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import defaultAvatar from './defaultAvatar.jpg';

const Img = styled.img`
  border-radius: 50%;
  box-sizing: content-box;
  background: #D9D9D9;
  object-fit: cover;
`;

function ProfilePicture({ profilePictureUrl, size }) {
  const [srcError, setSrcError] = useState(false);

  if (!profilePictureUrl || srcError) {
    return (
      <Img
        src={defaultAvatar}
        alt=""
        width={size}
        height={size}
      />
    );
  }

  return (
    <Img
      src={profilePictureUrl}
      alt=""
      width={size}
      height={size}
      onError={() => setSrcError(true)}
    />
  );
}

ProfilePicture.propTypes = {
  profilePictureUrl: PropTypes.string,
  size: PropTypes.number.isRequired,
};

ProfilePicture.defaultProps = {
  profilePictureUrl: defaultAvatar,
};

export default ProfilePicture;
